import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FieldConfig } from '../field.interface';

@Component({
  selector: 'calsoft-phone-no',
  templateUrl: './phone-no.component.html',
  styleUrls: ['./phone-no.component.scss']
})
export class PhoneNoComponent implements OnInit {
  event: any;
  pinform: FormGroup;
  //filteredCountries: any[];
  searchControl = new FormControl();
  validationErr:boolean;


  @Input() field: FieldConfig;
  @Input() customField13: any = 0;
  @Input() group: UntypedFormGroup;
  @Input() type: any;
  @Output() valueChange = new EventEmitter<any>();
  inputLength: number = 0;
  display: boolean = false;
  resultvalue: boolean = false;

  
  originalCountries: { id: string, name: string, flag:string }[] = [
    { id: '1', name: 'India +91', flag:'https://flagcdn.com/16x12/in.png'}, { id: '2', name: 'Afghanistan +93', flag:'https://flagcdn.com/16x12/af.png' }, { id: '3', name: 'Albania +355', flag:'https://flagcdn.com/16x12/al.png'},
    { id: '4', name: 'Algeria +213', flag:'https://flagcdn.com/16x12/dz.png' }, { id: '5', name: 'Andorra +376', flag:'https://flagcdn.com/16x12/ad.png' }, { id: '6', name: 'Angola +244', flag:'https://flagcdn.com/16x12/ao.png' },
    { id: '7', name: 'Argentina +54', flag:'https://flagcdn.com/16x12/ar.png' }, { id: '8', name: 'Armenia +374', flag:'https://flagcdn.com/16x12/am.png' }, { id: '9', name: 'Australia +61', flag:'https://flagcdn.com/16x12/au.png'},
    { id: '10', name: 'Austria +43', flag:'https://flagcdn.com/16x12/at.png' }, { id: '11', name: 'Azerbaijan +994', flag:'https://flagcdn.com/16x12/az.png' }, { id: '12', name: 'Bangladesh +880', flag:'https://flagcdn.com/16x12/bd.png' },
    { id: '13', name: 'United States +1', flag:'https://flagcdn.com/16x12/us.png'}, { id: '14', name: 'United Kingdom +44', flag:'https://flagcdn.com/16x12/gb.png' }, { id: '15', name: 'China +86', flag:'https://flagcdn.com/16x12/cn.png'},    
    { id: '16', name: 'Belgium +32', flag:'https://flagcdn.com/16x12/be.png' }, { id: '17', name: 'Bulgaria +359', flag:'https://flagcdn.com/16x12/bg.png' }, { id: '18', name: 'Bahrain +973', flag:'https://flagcdn.com/16x12/bh.png' },    
    { id: '19', name: 'Burundi +257', flag:'https://flagcdn.com/16x12/bi.png' }, { id: '20', name: 'Benin +229', flag:'https://flagcdn.com/16x12/bj.png' }, { id: '21', name: 'Bermuda +1441', flag:'https://flagcdn.com/16x12/bm.png' },    
    { id: '22', name: 'Brunei +673', flag:'https://flagcdn.com/16x12/in.png'}, { id: '23', name: 'Bolivia +591', flag:'https://flagcdn.com/16x12/in.png' }, { id: '24', name: 'Brazil +55', flag:'https://flagcdn.com/16x12/in.png'},    
    { id: '25', name: 'Bahamas +1242', flag:'https://flagcdn.com/16x12/bs.png' }, { id: '26', name: 'Bhutan +975', flag:'https://flagcdn.com/16x12/bt.png'}, { id: '27', name: 'Botswana +267', flag:'https://flagcdn.com/16x12/bw.png' },    
    { id: '28', name: 'Belarus +375', flag:'https://flagcdn.com/16x12/by.png' }, { id: '29', name: 'Belize +501', flag:'https://flagcdn.com/16x12/bz.png'}, { id: '30', name: 'Canada +1', flag:'https://flagcdn.com/16x12/ca.png' },    

  ];
  filteredCountries: { id: string, name: string, flag:string}[] = [];
  MobmaxLength: any;
  invalidNumber: boolean=false;

  constructor(private fb: FormBuilder, private sanitizer: DomSanitizer) {
    this.validationErr = false;
    this.MobmaxLength = 10;
  }
 
  ngOnInit(): void {
    this.pinform = this.fb.group({
      endTimeHour: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(10)]],
      ampm: ['1', Validators.required],
      flag:[]
    });

 // Initialize filteredCountries with the original list
    this.filteredCountries = this.originalCountries.slice();

    this.searchControl.valueChanges.subscribe(value => {
      this.filterCountries(value);
    });


    const control = this.group.get(this.field.name);
    if (control) {
      control.valueChanges.subscribe(value => {
        this.inputLength = value?.length;
     //   this.checkCondition();
      });
    }

    if (this.group != undefined) {
      this.display = true;
    }

  }

  get endTimeHour() {
    return this.pinform.get('endTimeHour');
  }

  getFlagUrl(code: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(code);
  }

  onSelectionChange(event: any) {
    const selectedValue = event.value;
    console.log('Selected value:', selectedValue);

    let minLength = 1;
    let maxLength = 10;

    switch (selectedValue) {
      case '2':
        maxLength = 9;
        break;
      case '5':
        maxLength = 6;
        break;
      case '7':
        maxLength = 13;
        break;
      case '12':
        maxLength = 11;
        break;
      case '15':
          maxLength = 11;
        break;
        case '16':
          maxLength = 9;
        break;
        case '17':
          maxLength = 9;
        break;
        case '18':
          maxLength = 8;
        break;        
        case '19':
          maxLength = 8;
        break;                
        case '20':
          maxLength = 8;
        break;         
        case '21':
          maxLength = 7;
        break;                                       
        case '22':
          maxLength = 7;
        break;                                               
        case '23':
          maxLength = 8;
        break;                                               
        case '24':
          maxLength = 11;
        break;                                               
        case '25':
          maxLength = 7;
        break;                                               
        case '26':
          maxLength = 8;
        break;                                               
        case '27':
          maxLength = 8;
        break;                                               
        case '28':
          maxLength = 11;
        break;                                               
        case '29':
          maxLength = 7;
        break;                                               



        default:
        maxLength = 10;
        break;
    }

    this.pinform.get('endTimeHour').setValidators([Validators.required, Validators.minLength(minLength), Validators.maxLength(maxLength)]);
    this.pinform.get('endTimeHour').updateValueAndValidity();
    this.MobmaxLength = maxLength;
    this.pinform.value.endTimeHour = '';
  }

  getCountryCode(value: string): string {
    switch (value) {
      case '1':
        return '+91';
      case '2':
        return '+93';
      case '3':
        return '+355';
      case '4':
        return '+213';
      case '5':
        return '+376';
      case '6':
        return '+244';
      case '7':
        return '+54';
      case '8':
        return '+374';
      case '9':
        return '+61';
      case '10':
        return '+43';
      case '11':
        return '+994';
      case '12':
        return '+880';
      case '13':
        return '+1';
      case '14':
        return '+44';
      case '15':
        return '+86';
      case '16':
        return '+32';
      case '17':
        return '+359';
      case '18':
        return '+973';
      case '19':
        return '+257';
      case '20':
        return '+229';
      case '21':
        return '+1441';
      case '22':
        return '+673';
      case '23':
        return '+591';
      case '24':
        return '+55';        
        case '25':
          return '+1242';
        case '26':
          return '+975';
        case '27':
          return '+267';        
          case '28':
            return '+375';
          case '29':
            return '+501';
          case '30':
            return '+1';        
  


          default:
        return '';
    }
  }

 filterCountries(value: string): void {
    const filterValue = value.toLowerCase();
    if (!filterValue) {
      // If the search input is empty, display the original list
      this.filteredCountries = this.originalCountries.slice();
    } else {
      // Filter the countries based on the search input
      this.filteredCountries = this.originalCountries.filter(country =>
        country.name.toLowerCase().includes(filterValue)
      );
    }
  }

  preventMatSelectOpen(event: Event) {
    event.stopPropagation();
  }


  onSubmit() {
    // console.log(this.pinform.value);
    // console.log(this.getCountryCode(this.pinform.get('ampm').value));
    let params : any = {
      phone:this.pinform.value.endTimeHour,
      id:this.pinform.value.ampm,
      code:this.getCountryCode(this.pinform.get('ampm').value)
      }
    
  }
  
  validateIndianPhoneNumber(value: string) {
    const indianPhoneNumberPattern = /^[6789]\d{9}$/;
    if (!indianPhoneNumberPattern.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  validate_PhoneNumber(value: string) {
    const indianPhoneNumberPattern =  /^[1234567890]\d*$/;
    if (!indianPhoneNumberPattern.test(value)) {
     
      this.invalidNumber=true;
    
    } else {
     
      this.invalidNumber=false;
    }
  }

  errmsg(event){
    const mobile = this.pinform.get('endTimeHour').value;
    const selectedCountry = this.pinform.get('ampm').value;
  
    let validationResult: any = null;
  
    switch (selectedCountry) {
      case '1': // India
        validationResult = this.validateIndianPhoneNumber(mobile);
        break;
      case '2': // Afghanistan
        validationResult = this.validate_PhoneNumber(mobile);
        break;
      default:
        validationResult = this.validate_PhoneNumber(mobile);
        break;
    }
    const endTimeHourControl = this.pinform.get('endTimeHour');

    const teamControl = this.group.get(this.field.name);

    if (mobile.length !== this.MobmaxLength) {
      validationResult = true;
    }
  
    if (validationResult) {
      this.validationErr = true;
      teamControl.setErrors({ customError: validationResult });
      endTimeHourControl.setErrors({ customError: validationResult });
    } else {
      this.validationErr = false;
      // No validation error, proceed with form submission
      console.log('Valid number');
      endTimeHourControl.setErrors(null);
      teamControl.setErrors(null);
    }
  }

  isSubmitEnabled(): boolean {
    if (
        !this.pinform.get('endTimeHour').errors?.required &&
        this.MobmaxLength === this.pinform.get('endTimeHour').value.length &&
        !this.pinform.get('endTimeHour').errors?.maxlength &&
        !this.validationErr
    ) {
        return true; // Enable submit button
    } else {
        return false; // Disable submit button
    }
}

changeValue(value,text){
  
  text=text.replace(/[^0-9]/g, '')
  
  this.pinform.value[value] = text

  let index = this.originalCountries.findIndex(x => x.id == this.pinform.value.ampm);

if(index>-1){
  let valueCountry= this.originalCountries[0].name;

  valueCountry = valueCountry.split(' ')[1];
 
   this.group.get(this.field.name).setValue(valueCountry+text);
}


  
  this.pinform.patchValue(this.pinform.value)
}


ngOnChanges(changes: SimpleChanges): void {
  if (changes.field && changes.field.currentValue) {
    const control = this.group.get(this.field.name);
    if (control) {
      control.valueChanges.subscribe(value => {
        this.inputLength = value?.length;
      });
    }
  }
}


isRequired(fieldName: string): boolean {
  const control = this.group.get(fieldName);
  return control && control.hasError('required');
}



setValue(value: any) {
  if (this.group.get(this.field.name)?.valid) {
    this.group.get(this.field.name).setValue(value);
    this.valueChange.emit( this.field.name);
  } else {
    console.error('Form control is invalid');
  }
}

}

